@import '../../constants.scss';

.container {
  display: none;
  &.offset {
    margin-top: 32px;
  }
}

.logo {
  margin: 16px auto;
  text-align: center;
}

@media (max-width: $WIDTH_LG) {
  .container {
    display: block;
  }
}