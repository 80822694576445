@import '../../../constants.scss';

.stats {
  display: grid;
  .top {
    display: grid;
    grid-template-columns: 1fr 250px;
    justify-items: flex-end;
    padding-bottom: 24px;
    border-bottom: 1px solid;
    .type {
      .info {
        margin-top: 4px;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 8px;
        align-items: center;
        justify-content: flex-end;
        h6 {
          line-height: 1;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
  .total {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) 250px 250px;
    column-gap: 4px;
    justify-items: flex-end;
    padding: 24px 0;
    border-bottom: 1px solid;
    .col {
      justify-items: flex-end;
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: 8px;
    }
    > *:first-child {
      justify-self: flex-start;
    }
    h5 {
      font-weight: bolder;
      text-align: right;
    }
  }
}

.referrals {
  // display: grid;
  // grid-auto-flow: row;
  // grid-row-gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid;
}

@media (max-width: $WIDTH_XL) {
  .stats {
    .total {
      grid-template-columns: minmax(250px, 1fr) 200px 200px;
    }
  }
  .filter {
    grid-column: 1/4;
  }
  .second {
    grid-column: 2/3;
  }
  .third {
    grid-column: 3/4;
  }
}

@media (max-width: $WIDTH_MD) {
  .stats {
    .total {
      grid-template-columns: minmax(180px, 1fr) 100px 100px;
    }
  }
}

@media (max-width: $WIDTH_SM) {
  .stats {
    .total {
      grid-template-columns: minmax(90px, 1fr) 80px 80px;
    }
  }
}
