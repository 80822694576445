.centerBtn {
  margin-top: 2rem;
  text-align: center;
}

.rightBtn {
  margin-top: 2rem;
  text-align: right;
}

div.dialogPaper {
  margin: 10px;
}
.upper_row {
  display: flex;
  justify-content: space-between;
}
.title {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.selectField > div > div {
  padding-top: 18.5px;
  padding-bottom: 18.5px;
}
.control {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  button {
    width: 100%;
  }
}
