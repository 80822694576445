.container {
  margin-top: 16px;
  margin-bottom: 16px;
  .code {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 16px;
    margin-top: 24px;
  }
  h6 {
    font-weight: bold;
  }
  .button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 16px;
  }
}
