.block {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 32px;
  justify-items: center;
  h5 {
    line-height: 2rem;
  }
}

.icon {
  font-size: 144px !important;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
