.container {
  // padding: 36px 0;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  grid-template-columns: minmax(0, 100%);
  max-width: 100%;
  h4 {
    font-family: 'TT Norms', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 24px;
  }
}

.filter {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-content: flex-start;
}