.container {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 25px;
}

.item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 24px 120px 8px;
  grid-column-gap: 25px;
  align-items: center;
  text-decoration: none;
  color: inherit;
  user-select: none;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

a.item:hover {
  text-decoration: none;
}

.item.dark {
  &:hover {
    svg, > p {
      color: #3F86F7 !important;
      fill: #3F86F7 !important;
    }
  }
}


.active.dark {
  svg, p {
    color: #3F86F7;
    fill: #3F86F7;
  }
}

.item.light {
  &:hover {
    svg, > p {
      color: #3F86F7 !important;
      fill: #3F86F7 !important;
    }
  }
}

.active.light {
  svg, p {
    color: #3F86F7;
    fill: #3F86F7;
  }
}

.sub {
  margin-top: 16px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;
}

.icon {
  transition: all .3s ease-in-out !important;
  transform: rotate(0);
  &.rotate {
    transform: rotate(-180deg);
  }
}

.new {
  background: #C62A07;
  border-radius: 3px;
  padding: 0 4px;
  width: 37px;
  p {
    color: #FFF !important;
  }
}
