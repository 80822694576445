.container {
  display: grid;
  grid-auto-flow: column;
  text-transform: uppercase;
  grid-column-gap: 8px;
  p {
    cursor: pointer;
  }
  p:not(:last-of-type) {
    border-right: 1px solid #3A3F45;
    padding-right: 8px;
  }
}
