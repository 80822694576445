@import '../../../constants.scss';

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 40px;
  padding: 24px 16px;
  .form {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    justify-items: flex-start;
    align-items: center;
  }
  .right {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 50px;
    justify-content: flex-end;
    align-items: center;
  }
  .copy {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 8px;
    min-width: 180px;
    button {
      white-space: nowrap;
    }
  }
}

.link {
  .linkContent {
    margin-top: 0.5rem;
    display: flex;
    column-gap: 1rem;
    align-items: flex-start;
    svg {
      cursor: pointer;
    }
  }
}

.linkContainer {
  padding: 24px 16px;
  display: none;
}

.dialog {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: $WIDTH_LG) {
  .container {
    grid-template-columns: 1fr;
    .form {
      grid-auto-flow: row;
      row-gap: 1.5rem;
      button {
        width: 100%;
      }
    }
    .right {
      display: none;
    }
  }
  .linkContainer {
    display: flex;
    justify-content: space-between;
  }
}
