@import '../../constants.scss';

.container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  height: fit-content;
}

.content {
  background-color: #28292c;
  display: flex;
  flex-direction: column;
  padding: 100px 190px;
  flex-grow: 1;
  border-radius: 4px;
  min-width: $WIDTH_MD;
}

.title {
  text-transform: uppercase;
  &:after {
    content: '';
    background: #1466be;
    display: block;
    margin-top: 5px;
    height: 4px;
    width: 90px;
    margin-bottom: 20px;
  }
}

@media (max-width: $WIDTH_LG) {
  .content {
    padding: 50px 100px;
    min-width: $WIDTH_SM;
  }
}

@media (max-width: $WIDTH_MD) {
  .content {
    padding: 20px 30px;
    min-width: auto;
  }
}
