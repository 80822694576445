@import '../../../constants.scss';

.container {
  padding: 24px 16px;
  display: flex;
  justify-content: flex-end;
  column-gap: 2rem;
  .content {
    display: flex;
    align-items: baseline;
    column-gap: 0.5rem;
    h4 {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

@media (max-width: $WIDTH_LG) {
  .container {
    flex-direction: column;
    grid-row: 2;
    row-gap: 1.5rem;
    .content {
      justify-content: space-between;
    }
  }
}
