@import '../../constants.scss';

.container {
  width: 100%;
  padding: 0 48px;
  display: grid;
  grid-template-columns: 150px auto;
  grid-column-gap: 36px;
  align-items: flex-start;
  &.extendedGrid {
    grid-column-gap: 120px;
  }
}

.addMargin {
  margin-left: 80px;
}

@media (max-width: $WIDTH_LG) {
  .menu {
    display: none;
  }
  .container {
    display: block;
    padding: 0 1rem;
  }
}
