.warning {
  position: absolute;
  width: 100%;
  height: 32px;
  background-color: #f24773;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 999;
}
