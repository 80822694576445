.link {
  width: 100%;
  .linkContent {
    margin-top: 0.5rem;
    display: flex;
    justify-content: start;
    column-gap: 1rem;
    align-items: flex-start;
    h6 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  svg {
    cursor: pointer;
  }
}

.referral {
  margin-top: 16px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}