@import "../../../constants.scss";

.item, .header {
  display: grid;
  column-gap: 5px;
  grid-template-columns: 100px 80px 80px 130px 100px auto;
  align-items: center;
  border-bottom: 1px solid #656669;
  padding: 16px;
  > *:last-child {
    justify-self: flex-end;
  }
}

.amount {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 14px;
  align-items: center;
  grid-template-columns: 16px auto;
  img {
    height: 19px;
    width: auto;
  }
}

.columnName {
  display: none;
}

@media (max-width: 1250px) {
  .header {
    display: none;
  }
  p.columnName {
    margin-top: .5rem;
    display: block;
  }
  .item {
    grid-template-columns: 150px 1fr auto;
    padding: .5rem 0px;
    .address {
      grid-column: 1/4;
      justify-self: start;
      width: 100%;
      direction: rtl;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      pre {
        overflow: hidden;
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;
      }
      &.topMargin {
        margin-top: .5rem;
      }
    }
  }
}
