button.icon {
  float: left;
  padding: 0;
  margin-left: 1rem;
  position: absolute;
  svg {
    font-size: 2.6rem;
  }
}
.content {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.buttons {
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
}
.authInfo {
  display: flex;
  column-gap: 1rem;
}