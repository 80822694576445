.container {
  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  right: -32px;
  top: calc(50% - 4px);
}
