.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h6.alertNote {
  margin-bottom: 24px;
  color: red;
}
