.app {
  max-width: 1688px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  &.vi {
    h4, h5 {
      font-family: 'Big Shoulders Text', sans-serif !important;
    }
  }
}

.content {
  display: flex;
  flex-shrink: 0;
  margin-top: 24px;
  min-height: calc(100% - 140px - 128px - 64px);
}

.exchangeAnon {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
}
