.container {
  &:before {
    display: none;
  }

  > div:nth-of-type(2) {
    min-height: 130px !important;
    visibility: visible;
    // mask-image: linear-gradient(rgba(0, 0, 0, 1) 40%, transparent 95%);
  }
}
