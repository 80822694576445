@font-face {
  font-family: "Big Shoulders Text";
  font-weight: normal;
  src: url("./resources/fonts/BigShouldersText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 900;
  src: url("./resources/fonts/TTNorms-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 300;
  src: url("./resources/fonts/TTNorms-Light.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 300;
  src: url("./resources/fonts/TTNorms-Light.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 600;
  src: url("./resources/fonts/TTNorms-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: normal;
  src: url("./resources/fonts/SFProText-Regular.otf") format("opentype");
}

body, html, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

pre {
  margin: 0;
}

a {
  color: #3F86F7;
  text-decoration: none;
}
