@import '../../../../../constants.scss';

.main {
  display: grid;
  grid-template-columns: 100px 200px 200px 300px 1fr;
  gap: 0.5rem;
  padding: 12px 16px;
  border-bottom: 1px solid #656669;
  .lastColumn {
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    button {
      white-space: nowrap;
    }
  }
  .columnName {
    display: none;
  }
  p.Verified {
    color: #48F04B
  }
  p.NotVerified {
    color: #E82626;
    display: flex;
    column-gap: .5rem;
  }
  .icon {
    margin-right: 0.5rem;
  }
}

@media (max-width: $WIDTH_LG) {
  .main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    .lastColumn{
      grid-column: 1/3;
      justify-content: space-between;
    }
    .columnName {
      display: block;
    }
    .onlyDesktop {
      display: none;
    }
    .icon {
      margin-right: 0;
    }
  }
  .main.header {
    display: none;
  }
}