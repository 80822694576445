
.dateRange {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 8px;
  justify-items: flex-start;
  .input {
    min-width: 220px;
  }
}

.picker {
  position: absolute;
  top: 42px;
  z-index: 2;
}

.rdrCustom {
  border: 1px solid;
  border-radius: 10px;
  :global {
    .rdrDateDisplayWrapper {
      display: none;
    }

    .rdrMonthAndYearWrapper {
      padding-top: 0;
    }

    .rdrInputRanges {
      display: none;
    }

    .rdrDefinedRangesWrapper {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    .rdrDayNumber {
      > span::after {
        display: none;
      }
    }

    .rdrStaticRanges {
      margin-top: 12px;
      > button:first-child {
        border-top-left-radius: 10px;
      }
    }

    .rdrStaticRange {
      &:hover > span {
        background: transparent !important;
      }
    }

    .rdrCalendarWrapper {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
    .rdrNextPrevButton {
      background: none;
    }

    .rdrDayDisabled {
      background: none;
    }

  }
}
