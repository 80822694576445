@import '../../constants.scss';

.container {
  padding: 24px 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.offset {
    margin-top: 32px;
  }
  a {
    display: flex;
    justify-content: center;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 12px;
    align-items: center;
  }
  .authInfo {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 25px;
    align-items: center;
  }
}

@media (max-width: $WIDTH_LG) {
  .container {
    display: none;
  }
}
