@import '../../constants.scss';

.form {
   display: flex;
   flex-direction: column;
   align-items: center;
  .preventAutoComplete {
    opacity: 0;
    max-height: 0;
    input {
      max-height: 0;
      padding: 0;
      border: 0;
    }
  }
 }

.buttons {
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
}

@media (max-width: $WIDTH_MD) {
  .main {
    width: 100%;
  }
}

@media (max-width: $WIDTH_SM) {
  .buttons {
    grid-auto-flow: row;
    grid-row-gap: 12px;
  }
} 
