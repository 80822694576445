.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-family: 'TT Norms', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }
  h5, h6 {
    margin-top: 24px;
  }
}
