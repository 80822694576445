@import '../../../constants.scss';

.container {
  display: grid;
  grid-template-columns: minmax(250px, 1fr) 250px 250px;
  column-gap: 4px;
  justify-items: flex-end;
}

.first {
  justify-self: flex-start;
}

.child {
  grid-column: 1/4;
  width: 100%;
  & > div > div {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) 250px 250px;
    column-gap: 4px;
    justify-items: flex-end;
    .first {
      justify-self: flex-start;
    }
  }
  &.two_columns > div > div {
    grid-template-columns: minmax(250px, 1fr) 1fr 0px;
  }
}

.childHide {
  display: none;
}

.two_columns {
  grid-template-columns: minmax(250px, 1fr) 1fr 0px;
}

.expander {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: flex-start;
  .icon {
    transition: all 0.3s ease-in-out !important;
    transform: rotate(0);
    &.rotate {
      transform: rotate(-180deg);
    }
  }
}

.sub,
.col {
  p {
    text-align: right;
  }
}

.referral {
  p {
    text-align: unset !important;
  }
}

h6.title {
  display: flex;
  align-items: center;
  column-gap: 14px;
  margin-bottom: 14px;
  .addIcon,
  .removeIcon {
    cursor: pointer;
  }
  svg {
    border: 1px solid;
    border-radius: 6px;
  }
}

@media (max-width: $WIDTH_XL) {
  .container {
    grid-template-columns: minmax(250px, 1fr) 200px 200px;
  }
  .two_columns {
    grid-template-columns: minmax(250px, 1fr) 200px 0px;
  }

  .child {
    & > div > div {
      grid-template-columns: minmax(250px, 1fr) 200px 200px;
    }
    &.two_columns > div > div {
      grid-template-columns: minmax(250px, 1fr) 200px 0px;
    }
  }
}

@media (max-width: $WIDTH_MD) {
  .container {
    grid-template-columns: minmax(180px, 1fr) 100px 100px;
  }
  .two_columns {
    grid-template-columns: minmax(180px, 1fr) 100px 0px;
  }
  .child {
    & > div > div {
      grid-template-columns: minmax(180px, 1fr) 100px 100px;
    }
    &.two_columns > div > div {
      grid-template-columns: minmax(180px, 1fr) 100px 0px;
    }
  }
}

@media (max-width: $WIDTH_SM) {
  .container {
    grid-template-columns: minmax(90px, 1fr) 80px 80px;
  }
  .two_columns {
    grid-template-columns: minmax(90px, 1fr) 80px 0px;
  }
  .child {
    & > div > div {
      grid-template-columns: minmax(90px, 1fr) 80px 80px;
    }
    &.two_columns > div > div {
      grid-template-columns: minmax(90px, 1fr) 80px 0px;
    }
  }
}
