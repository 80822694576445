div.dialogPaper {
  margin: 10px;
}
.close {
  text-align: right;
  cursor: pointer;
}
h3.title {
  font-weight: 600;
  margin-bottom: 1rem;
}
.selectField > div > div {
  padding-top: 18.5px;
  padding-bottom: 18.5px;
}
.control {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  button {
    width: 100%;
  }
}