@import '../../constants.scss';

.container {
  h4 {
    font-family: 'TT Norms', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 24px;
  }
}

.labelInfo {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  grid-auto-flow: column;
}

.content {
  margin-top: 24px;
  display: grid;
  gap: 2rem;
  grid-template-columns: minmax(250px, 1fr) 400px;
  justify-content: space-between;
  align-items: start;
  h4 {
    font-family: 'TT Norms', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 16px;
  }
}

.tabs {
  display: grid;
  grid-auto-flow: row;
}

@media (max-width: $WIDTH_MD) {
  .content {
    margin-top: 24px;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
}
