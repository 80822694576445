.top {
  padding-top: 24px;
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  &.withdraw {
    justify-content: flex-end;
  }
}

.right {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.items {
  margin-top: 32px;
  display: grid;
  grid-auto-flow: row;
}

.content {
  h4 {
    font-family: 'TT Norms', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 24px;
  }
}


