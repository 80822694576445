.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.theme {
  display: flex;
  align-items: center;
}
